<template>
  <div class="authincation section-padding">
    <div class="container h-100">
      <div class="row justify-content-center h-100 align-items-center">
        <div class="col-xl-5 col-md-6">
          <div class="auth-form card">
            <div class="card-body">
              <h3 class="text-center">تایید شماره موبایل</h3>
              <p class="text-center mb-3 mt-3">
                کد ارسال شده 2 دقیقه اعتبار دارد
              </p>
              <form action="dashboard.html">
                <div class="form-group">
                  <label>کد تایید</label>
                  <input
                    type="text"
                    class="form-control text-center font-weight-bold"
                    value="11 22 33"
                  />
                </div>
                <div class="text-center">
                  <button type="submit" class="btn btn-success btn-block">
                    تایید
                  </button>
                </div>
              </form>
              <div class="info mt-3">
                <p class="text-muted">قبل از ارسال مجدد 2 دقیقه صبر نمایید</p>
              </div>
              <router-link class="page-back text-muted" to>
                <el-button @click="$router.back(-1)">
                  بازگشت <i class="fa fa-angle-left"></i
                ></el-button>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  mounted() {
    // Toggle navigation on mobile
    if (window.innerWidth < 991) {
      var nav = document.querySelector(".navbar-collapse.collapse.show");
      if (nav != undefined) nav.classList.toggle("show");
    }
  },
};
</script>
