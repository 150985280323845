<template>
  <div class="intro2" id="intro" data-scroll-index="0">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-xl-7 col-lg-12">
          <div class="intro-content text-center">
            <h1>تماس با ما</h1>
            <p>یو تتر - مرجع خرید تتر ارزان و آسان</p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="contact-form section-padding" data-scroll-index="4">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-xl-7">
          <div class="section-title text-center">
            <span>سوالی دارید ؟</span>
            <h2>به ما مستقیم پیام دهید</h2>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-4 col-md-4 col-sm-12">
          <div class="info-list">
            <h4 class="mb-3">آدرس</h4>
            <ul>
              <li><i class="fa fa-map-marker"></i> ایران ، تهران</li>
              <li>
                <i class="fa fa-phone"></i>
                <a href="tel:02144696193">02144696193</a>
              </li>
              <li><i class="fa fa-envelope"></i> info@tether.ir</li>
            </ul>
          </div>
        </div>
        <div class="col-xl-8 col-md-8 col-sm-12">
          <form method="post" name="myform" class="contact_validate">
            <div class="row">
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label> نام کامل </label>
                  <input
                    type="text"
                    class="form-control"
                    id="contactName"
                    placeholder="نام کامل"
                    name="firstname"
                  />
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label> ایمیل </label>
                  <input
                    type="email"
                    class="form-control"
                    name="email"
                    placeholder="hello@domain.com"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <textarea
                    class="form-control p-3"
                    name="message"
                    rows="5"
                    placeholder="سوالات و یا درخواست پشتیبانی خود را اینجا برامون بنویسید"
                  ></textarea>
                </div>
              </div>
            </div>
            <button type="submit" class="btn btn-primary px-4 py-2">
              ارسال پیام
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  mounted() {
    // Toggle navigation on mobile
    if (window.innerWidth < 991) {
      var nav = document.querySelector(".navbar-collapse.collapse.show");
      if (nav != undefined) nav.classList.toggle("show");
    }
  },
};
</script>
