<template>
  <div class="authincation section-padding">
    <div class="container h-100">
      <div class="row justify-content-center h-100 align-items-center">
        <div class="col-xl-5 col-md-6">
          <div class="auth-form card">
            <div class="card-header justify-content-center">
              <h4 class="card-title">ثبت نام</h4>
            </div>
            <div class="card-body">
              <form method="post" name="myform" class="signup_validate">
                <div class="form-group">
                  <label>شماره موبایل</label>
                  <div class="input-group mb-3">
                    <input
                      type="text"
                      class="form-control left-text"
                      placeholder="موبایل خود را وارد نمایید"
                      value=""
                    />
                    <div class="input-group-prepend">
                      <span class="input-group-text pl-4 pr-4"
                        ><img width="24" src="images/toman.svg" class="" />
                        &nbsp; +98
                      </span>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label>ایمیل</label>
                  <input
                    type="email"
                    class="form-control"
                    placeholder="hello@example.com"
                    name="email"
                  />
                </div>
                <div class="form-group">
                  <label>کلمه عبور</label>
                  <input
                    type="password"
                    class="form-control"
                    placeholder="رمز خود را وارد نمایید"
                    name="password"
                  />
                </div>
                <div class="form-group">
                  <label>تکرار کلمه عبور</label>
                  <input
                    type="password"
                    class="form-control"
                    placeholder="رمز خود را مجدد وارد نمایید"
                    name="passwordConfirm"
                  />
                </div>
                <div class="text-center mt-4">
                  <button type="submit" class="btn btn-success btn-block">
                    ثبت نام
                  </button>
                </div>
              </form>
              <div class="new-account mt-3">
                <p>
                  ثبلا ثبت نام کرده اید ؟
                  <!-- <a class="text-primary" href="signin.html">ورود به سیستم</a> -->
                  <router-link class="text-primary" to="/login"
                    >ورود به سیستم</router-link
                  >
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  mounted() {
    // Toggle navigation on mobile
    if (window.innerWidth < 991) {
      var nav = document.querySelector(".navbar-collapse.collapse.show");
      if (nav != undefined) nav.classList.toggle("show");
    }
  },
};
</script>
