<template>
  <div class="intro2" id="intro" data-scroll-index="0">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-xl-7 col-lg-12">
          <div class="intro-content text-center">
            <h1>درباره ما</h1>
            <p>یو تتر - مرجع خرید تتر ارزان و آسان</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="about-one section-padding">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6">
          <div class="service-img">
            <img src="images/app2.png" alt="" class="img-fluid" />
          </div>
        </div>
        <div class="col-lg-6">
          <div class="service-content m-t-50">
            <h3>شروع یک ایده</h3>
            <p>
              یو تتر در ابتدای سال 1400 هجری شمسی راه اندازی شد. ایده اولیه ما
              فروش تتر ارزان برای بازار ارز های دیجیتال کشور بود.
            </p>
            <p>
              از آنجا که بازار ارزهای دیجیتال در سراسر دینا بسیار پر طرفدار شده
              بود و رونق زیادی داشت یکی از نیاز های بازار تامین و وجود پلتفرمی
              مطمئن برای خرید آسان و با نرخ مناسب تتر که مرجع تمامی ارز ها
              میباشد بود. از این رو به ساخت این پلتفرم افتادیم و شروع کردیم
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="our-ceo py-5">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="ceo-content">
            <div class="media">
              <img
                src="/images/testimonial/1.jpg"
                width="120px"
                alt=""
                class="img-fluid ml-4"
              />
              <div class="media-body">
                <h3>مسعود احتشامی معینی</h3>
                <span>مدیر فنی یو تتر</span>
                <p class="mt-2">
                  ساخت پلتفرم های بومی خرید و فروش ارز های دیجیتال امروز یک نیاز
                  است و فردا برای گام های بعدی یک پیش نیاز خواهد بود
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="about-two section-padding">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6">
          <div class="service-content my-5">
            <h3>همکاری با یو تتر</h3>
            <p>
              اگر در هر زمینه مرتبط تخصص دارید برای ما رزومه ارسال کنید . تا در
              محیطی کاملا حرفه ای همکار هم باشیم
            </p>
            <a href="#" class="btn btn-primary">تماس با ما</a>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="service-img">
            <img src="images/app2.png" alt="" class="img-fluid" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  mounted() {
    // Toggle navigation on mobile
    if (window.innerWidth < 991) {
      var nav = document.querySelector(".navbar-collapse.collapse.show");
      if (nav != undefined) nav.classList.toggle("show");
    }
  },
};
</script>
